export const Jobs = [
    {
        title: "Full stack AWS project for start-up",
        yearStart: "2020",
        yearEnd: "2020",
        points: [
            "ReactJS front-end, NodeJS back-end.",
            "MySQL Database (ERD & Server)."
        ]
    },
    {
        title: "Full stack developer at Tmura Information Systems",
        yearStart: "2018",
        yearEnd: "2019",
        points: [
            "Maintenance of legacy code.",
            "Front-end technologies: jQuery, MVC architecture, Vanilla JS, HTML5, CSS3.",
            "Back-end technologies: C#, SQL, REST APIs",
            `Projects include:
            \n o	Vacation search engine with Sabre API.
            \n o	Android WebView app.
            \n o	Recruitment manager (Web app).
            `
        ]
    },
    {
        title: "COO at start-up company",
        yearStart: "2016",
        yearEnd: "2017",
        points: [
            "Development and design input for company product.",
            "Indiegogo crowd-funding management (200% raised)."
        ] 
    },
    {
        title: "Israel Scouts Logistics Counsel",
        yearStart: "2015",
        yearEnd: "2016",
        points: [
            "Deployment of large-scale operations including seminars and field trips.",
            "Public speaking.",
            "Educational youth counseling."
        ]
    },
]

export const Education = [
    {
        title:"H.I.T- B.Sc. Computer Science",
        yearStart:"2017",
        yearEnd:"2020",
        description: "C#, Python, C++, Java, OpenGL & more."
    },
    {
        title:"Self-Tought",
        yearStart:"2017",
        yearEnd:"Today",
        description: "ReactJS, NodeJS, AWS, HTML, jQuery & more"
    },
]

export const Military = [
    {
        title:"Commander of special systems at unit 217",
        yearStart:"2011",
        yearEnd:"2014",
        points: [
            "Hands-on experience with newest IDF technologies in testing and operations including GPS and recording devices.",
            "Qualification of advanced, confidential weapon systems.",
            "Providing immediate solutions for operational needs."
        ]
    }
]

export const Languages = [
    {
        language:"Hebrew",
        level:"Native speaker"
    },
    {
        language:"English",
        level:"Native speaker"
    },
    {
        language:"Japanese",
        level:"Conversation level"
    },
]

export const AboutMe = 
    {
        fisrtName: "Tamuz",
        lastName: "Paran",
        phone:"+972 54 30 20 790",
        email:"tamuzp@gmail.com",
        profession:"Web Developer",
        facebook:"https://www.facebook.com/tamuzp",
        linkedin:"https://www.linkedin.com/in/tamuzp/",
        github:"https://github.com/tamuzp",
        about_me:`My name is Tamuz Paran, I live in Israel, and I was raised in Japan.
        \n I am fascinated by technology and gadgets from a young age, and consider it a hobby of mine.
        \n I will soon complete my bachelor's degree in computer science, I'm autodidactic and resourceful,
        \n and I don't shy away from a task that seems out of my league.
        `,
        contact:"Currently looking for a web developer position, but am also available for other projects or offers, feel free to contact me."
    }


export const ExperienceMenuItems = [
    {
        title: "Jobs",
        color: "rgb(159, 135, 175)"
    },
    {
        title: "Education",
        color: "rgb(0, 78, 100)"
    },
    {
        title: "Military",
        color: "rgb(0, 165, 207)"
    },
    {
        title: "Language",
        color: "rgb(98, 208, 173)"
    },
    {
        title: "Experience",
        color: "rgb(73, 53, 72)"
    }
]